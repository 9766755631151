<template>
  <vue-perfect-scrollbar
    :settings="{suppressScrollX: true, wheelPropagation: false}"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%;"
  >
    <div class="pa-5 relative">
      <v-app-bar
        class="pt-1"
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">
            Notifications
          </h6>
          <slot name="notificationDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>

    <div class="pa-5 mt-10">
      <!-- Draw Notification -->
    </div>
  </vue-perfect-scrollbar>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
  }
</script>
